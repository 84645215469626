import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AircraftAutocomplete from '../AircraftAutocomplete';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import QuoteLegs from './QuoteLegs';
import useLoggedUser from '../../../api/useLoggedUser';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import _get from 'lodash/get';
import _defaults from 'lodash/defaults';
import { AircraftResource, AirportResource, PendingRequest, VictorDate } from 'fortune-client';
import React, { useEffect, useState } from 'react';
import { Shape } from '~/types/yupExtensions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteConfig } from '~/routes/routeConfig';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';
import { VictorCurrencies } from 'fortune-client';
import OCR from '~/components/forms/QuoteForm/ocr';
import { ParsedQuote } from '~/components/forms/QuoteForm/ocr/parsers/types';
import useFindOperatorCharterRequest from '~/api/useFindOperatorCharterRequest';
import { OcrParsingError } from './ocr/parsers/base';
import OcrErrorMessage, { OCRError } from './OcrErrorMessage';
import useDataTestId from '~/hooks/useDataTestId';

export enum QuoteCatering {
  NOT_INCLUDED = 'Catering Not Included',
  CATERING = 'Catering',
  BUY_ON_BOARD = 'Buy on board',
  SNACKS = 'Catering (Snacks)',
  COLD = 'Catering (Cold)',
  HOT = 'Catering (Hot)',
  VIP = 'Catering (VIP)',
}

export enum QuoteWifi {
  FREE = 'free',
  EXTRA_FEE = 'extra-fee',
  NOT_AVAILABLE = 'not-available',
}

export enum QuoteFuelStop {
  NO_STOP = 'no-stop',
  POTENTIAL = 'potential',
  REQUIRED = 'required',
}

export enum QuoteFuelStopReasons {
  WEATHER = 'weather',
  TOTAL_PAYLOAD = 'totalPayload',
  DRY_RUNWAY = 'dryRunway',
}

export enum QuoteCustomsStop {
  NO_STOP = 'no-stop',
  REQUIRED = 'required',
}

export enum QuoteFlightAttendant {
  NOT_INCLUDED = 'not-included',
  INCLUDED = 'included',
}

export interface OperatorQuoteDataLeg {
  deptDate: VictorDate;
  deptAirport: Pick<AirportResource, 'id' | 'timeZone' | 'airportCode' | 'icao' | 'iata' | 'displayInfo'>;
  arrAirport: Pick<AirportResource, 'id' | 'timeZone' | 'airportCode' | 'icao' | 'iata' | 'displayInfo'>;
  passengers: number;
  flightTime: number;
}

export interface OperatorQuoteData {
  aircraft: string;
  operator: string;
  amount: number;
  currency: VictorCurrencies | '';
  catering: QuoteCatering;
  wifi: QuoteWifi | null;
  fuelStop: QuoteFuelStop;
  fuelStopReasons: QuoteFuelStopReasons[];
  customsStop: QuoteCustomsStop;
  flightAttendant: QuoteFlightAttendant;
  allowPets: boolean;
  isEmptyLeg: boolean;
  note: string;
  legs: OperatorQuoteDataLeg[];
  floatingFleet: boolean;
  modifiedRouting: boolean;
}

export interface IQuoteFormSchemaEditLeg {
  deptDate: Omit<VictorDate, 'locale'> | null;
  arrDate?: Omit<VictorDate, 'locale'> | null;
  deptAirport: Pick<AirportResource, 'id' | 'airportCode' | 'displayInfo' | 'timeZone' | 'icao'> | null;
  arrAirport: Pick<AirportResource, 'id' | 'airportCode' | 'displayInfo' | 'timeZone' | 'icao'> | null;
  passengers: number | undefined;
  flightTime: number | undefined;
  index?: number;
  isNew?: boolean;
}

export type IQuoteFormSchema = OperatorQuoteData & {
  editLeg: IQuoteFormSchemaEditLeg | null;
};

export interface QuoteFormProps {
  fileUploadRef: React.Ref<HTMLInputElement>;
  initialValues?: Partial<OperatorQuoteData>;
  error?: string | null;
  onSave: (values: IQuoteFormSchema) => Promise<void>;
  request: PendingRequest;
  dataTestId?: string;
}

type OperatorAirportData = Pick<AirportResource, 'id' | 'airportCode' | 'displayInfo' | 'timeZone'>;

const AirportSchema = Yup.object<Shape<OperatorAirportData>>({
  id: Yup.string(),
  airportCode: Yup.string(),
  displayInfo: Yup.string(),
  timeZone: Yup.string(),
});

const LegSchema = Yup.object<Shape<OperatorQuoteDataLeg>>({
  deptDate: Yup.object<Shape<Omit<VictorDate, 'locale'>>>({
    date: Yup.string(),
    time: Yup.string(),
    timeZone: Yup.string(),
  }).required('Required'),
  deptAirport: AirportSchema.required('Required'),
  arrAirport: AirportSchema.required('Required'),
  passengers: Yup.number().positive('Must be a positive number').required('Required'),
  flightTime: Yup.number().positive('Must be a valid time with format "hh:mm"').required('Required'),
});

const EditLegSchema = Yup.object<Shape<IQuoteFormSchemaEditLeg>>({
  deptDate: Yup.object<Shape<Omit<VictorDate, 'locale'>>>({
    date: Yup.string(),
    time: Yup.string(),
    timeZone: Yup.string(),
  })
    .nullable()
    .required('Required'),
  deptAirport: AirportSchema.nullable().required('Required'),
  arrAirport: AirportSchema.nullable().required('Required'),
  passengers: Yup.number().required('Required').positive('Must be a positive number'),
  flightTime: Yup.number().required('Required').positive('Must be a positive number'),
  index: Yup.number(),
});

const FormSchema = Yup.object<Shape<IQuoteFormSchema>>({
  aircraft: Yup.string().required('Required'),
  operator: Yup.string().required('Required'),
  amount: Yup.number().positive('Must be a positive number').required('Required'),
  currency: Yup.string().required('Required'),
  catering: Yup.string(),
  wifi: Yup.string().nullable(),
  fuelStop: Yup.string(),
  fuelStopReasons: Yup.array().of(Yup.string()),
  customsStop: Yup.string(),
  flightAttendant: Yup.string(),
  allowPets: Yup.bool(),
  isEmptyLeg: Yup.bool(),
  note: Yup.string(),
  legs: Yup.array().of(LegSchema),
  floatingFleet: Yup.bool(),
  modifiedRouting: Yup.bool(),
  editLeg: EditLegSchema.nullable(),
});

const getInitialValues = (request: PendingRequest, values: Partial<OperatorQuoteData> = {}): IQuoteFormSchema => {
  const defaultValues: IQuoteFormSchema = {
    aircraft: '',
    currency: '',
    note: '',
    catering: QuoteCatering.NOT_INCLUDED,
    wifi: QuoteWifi.NOT_AVAILABLE,
    fuelStop: QuoteFuelStop.NO_STOP,
    fuelStopReasons: [],
    customsStop: QuoteCustomsStop.NO_STOP,
    flightAttendant: QuoteFlightAttendant.NOT_INCLUDED,
    allowPets: false,
    isEmptyLeg: false,
    modifiedRouting: false,
    editLeg: null,
    operator: '',
    floatingFleet: false,
    amount: 0,
    legs: request.requestLegs.map((leg) => ({
      deptDate: { ...leg.deptDate },
      deptAirport: { ...leg.links.deptAirport },
      arrAirport: { ...leg.links.arrAirport },
      passengers: leg.passengers || 0,
      flightTime: 0,
    })),
  };
  return _defaults(values, defaultValues) as IQuoteFormSchema;
};

const MODIFIED_ROUTING_FIELDS = ['deptAirport.id', 'arrAirport.id', 'deptDate'];

const FUEL_STOP_REASONS: Record<QuoteFuelStopReasons, string> = {
  [QuoteFuelStopReasons.WEATHER]: 'Weather / winds',
  [QuoteFuelStopReasons.TOTAL_PAYLOAD]: 'Total payload',
  [QuoteFuelStopReasons.DRY_RUNWAY]: 'Dry runway',
};

export const QuoteForm: React.FC<QuoteFormProps> = ({
  fileUploadRef,
  initialValues: propsInitialValues,
  request,
  error,
  onSave,
  dataTestId,
}) => {
  const dt = useDataTestId(dataTestId);
  const { data } = useLoggedUser();
  const [searchParams] = useSearchParams();
  const operators = data?.operators ?? [];
  const [ocrError, setOcrError] = useState<OCRError>();

  const theme = useTheme();
  const navigate = useNavigate();

  const [initialValues] = useState(getInitialValues(request, propsInitialValues));

  const formik = useFormik<IQuoteFormSchema>({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      await onSave(values);
    },
  });

  const {
    values: { legs, modifiedRouting },
    setFieldValue,
  } = formik;

  const { data: operatorCharterRequest } = useFindOperatorCharterRequest(
    { operator: formik.values.operator, charterRequest: request.id },
    { enabled: Boolean(formik.values.operator) },
  );

  const { legs: initialLegs } = initialValues;
  useEffect(() => {
    const newModifiedRouting =
      legs.length !== initialLegs.length ||
      legs.some((leg, index) => {
        return MODIFIED_ROUTING_FIELDS.some((field) => {
          return !_isEqual(_get(leg, field), _get(initialLegs[index], field));
        });
      });
    if (newModifiedRouting !== modifiedRouting) setFieldValue('modifiedRouting', newModifiedRouting);
  }, [legs, setFieldValue, initialLegs, modifiedRouting]);

  const handleAircraftChanged = (aircraft: AircraftResource | null) => {
    analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
      field: 'aircraft',
    });
    formik.setFieldValue('aircraft', aircraft?.id ?? '');
    formik.setFieldValue('operator', aircraft?.links.operator ?? '');

    const operator = operators.find((el) => el.id === aircraft?.links.operator);
    formik.setFieldValue('floatingFleet', Boolean(operator?.floatingFleet));
  };

  const handleFuelsStopChecksChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
      field: 'fuelStopReasons',
    });
    formik.setFieldValue('fuelStopReasons', typeof value === 'string' ? value.split(',') : value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {
      target: { name },
    } = event;
    if (name) {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
        field: name,
      });
    }
    formik.handleChange(event);
  };

  const onOCRComplete = (quote: ParsedQuote, errors: OcrParsingError[]) => {
    formik.resetForm();

    if (!_isNull(quote.aircraft)) {
      formik.setFieldValue('aircraft', quote.aircraft);
      formik.setFieldValue('operator', quote.operator ?? '');

      const operator = operators.find((el) => el.id === quote.operator);
      formik.setFieldValue('floatingFleet', Boolean(operator?.floatingFleet));
    }

    if (!_isNull(quote.allowPets)) {
      formik.setFieldValue('allowPets', quote.allowPets);
    }

    if (!_isNull(quote.price)) {
      formik.setFieldValue('amount', quote.price.amount);
      formik.setFieldValue('currency', quote.price.currency);
    }

    if (!_isNull(quote.wifi)) {
      formik.setFieldValue('wifi', quote.wifi);
    }

    formik.setFieldValue(
      'legs',
      quote.legs.map((leg) => ({
        deptDate: leg.deptDate,
        deptAirport: leg.deptAirport,
        arrAirport: leg.arrAirport,
        passengers: leg.passengers,
        flightTime: leg.flightTime,
      })),
    );

    if (!_isNull(quote.fuelStop)) {
      formik.setFieldValue('fuelStop', quote.fuelStop);
    }
    if (!_isNull(quote.note)) {
      formik.setFieldValue('note', quote.note);
    }

    if (errors.length) {
      errors.forEach((error) => {
        console.log(error.context, error.stack ?? error.message);
      });

      setOcrError(errors);
    } else {
      setOcrError(undefined);
    }
  };

  const onOcrError = (err: unknown) => {
    console.log('Something went wrong', err);
    setOcrError(err as OCRError);
  };

  const quotes = operatorCharterRequest?.links.operatorQuotes ?? [];
  const currentOperatorGenuineQuote = Boolean(quotes.find((quote) => !quote.modifiedRouting));

  const currencies = request.currencies;

  return (
    <form style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }} onSubmit={formik.handleSubmit}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        New Quote for {request.vid}
      </Typography>

      <Typography variant="h6" sx={{ my: 2 }}>
        Primary details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <AircraftAutocomplete
            onAircraftChanged={handleAircraftChanged}
            error={formik.touched.aircraft && Boolean(formik.errors.aircraft)}
            helperText={(formik.touched.aircraft && formik.errors.aircraft) || undefined}
            disabled={formik.isSubmitting}
            value={formik.values.aircraft}
            dataTestId={dt('aircraft')}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3 }}>
          <TextField
            label="Price"
            type="number"
            name="amount"
            value={formik.values.amount || ''}
            onChange={handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
            disabled={formik.isSubmitting}
            inputProps={{
              'data-testid': dt('price'),
            }}
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3 }}>
          <TextField
            label="Currency"
            select
            name="currency"
            value={formik.values.currency || ''}
            onChange={handleChange}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
            disabled={formik.isSubmitting}
            inputProps={{
              'data-testid': dt('currency'),
            }}
          >
            {currencies
              ? currencies.map((curr) => (
                  <MenuItem value={curr} key={curr}>
                    {curr}
                  </MenuItem>
                ))
              : null}
          </TextField>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" sx={{ my: 2 }}>
        Flight features
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 6, md: 3 }}>
          <TextField
            label="Wi-Fi"
            select
            name="wifi"
            value={formik.values.wifi}
            onChange={handleChange}
            error={formik.touched.wifi && Boolean(formik.errors.wifi)}
            helperText={formik.touched.wifi && formik.errors.wifi}
            disabled={formik.isSubmitting}
          >
            <MenuItem value={QuoteWifi.FREE}>Free</MenuItem>
            <MenuItem value={QuoteWifi.EXTRA_FEE}>Extra Fee</MenuItem>
            <MenuItem value={QuoteWifi.NOT_AVAILABLE}>Not Available</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 6, lg: 4, md: 2 }}>
          <TextField
            label="Flight attendant"
            select
            name="flightAttendant"
            value={formik.values.flightAttendant}
            onChange={handleChange}
            error={formik.touched.flightAttendant && Boolean(formik.errors.flightAttendant)}
            helperText={formik.touched.flightAttendant && formik.errors.flightAttendant}
            disabled={formik.isSubmitting}
          >
            <MenuItem value={QuoteFlightAttendant.NOT_INCLUDED}>Not Included</MenuItem>
            <MenuItem value={QuoteFlightAttendant.INCLUDED}>Included</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, lg: 3, md: 6 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.allowPets}
                name="allowPets"
                color="primary"
                onChange={handleChange}
                inputProps={
                  {
                    'data-testid': dt('pets'),
                  } as React.InputHTMLAttributes<HTMLInputElement>
                }
              />
            }
            label="Allow pets"
          />
          <FormControlLabel
            control={
              <Checkbox checked={formik.values.isEmptyLeg} name="isEmptyLeg" color="primary" onChange={handleChange} />
            }
            label="Empty Leg"
          />
        </Grid>
        <Grid size={{ xs: 6, md: 3 }}>
          <TextField
            label="Fuel stop"
            select
            name="fuelStop"
            value={formik.values.fuelStop}
            onChange={handleChange}
            disabled={formik.isSubmitting}
          >
            <MenuItem value={QuoteFuelStop.NO_STOP}>No Stop</MenuItem>
            <MenuItem value={QuoteFuelStop.POTENTIAL}>Potential</MenuItem>
            <MenuItem value={QuoteFuelStop.REQUIRED}>Required</MenuItem>
          </TextField>
        </Grid>
        {formik.values.fuelStop !== QuoteFuelStop.NO_STOP && (
          <Grid size={{ xs: 6, md: 3 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="fuel-stop-checks-label">Reasons for fuel stop</InputLabel>
              <Select
                labelId="fuel-stop-checks-label"
                multiple
                value={formik.values.fuelStopReasons}
                onChange={handleFuelsStopChecksChange}
                input={<OutlinedInput label="Reasons for fuel stop" />}
                renderValue={(selected) => selected.map((key) => FUEL_STOP_REASONS[key]).join(', ')}
                disabled={formik.isSubmitting}
              >
                {(Object.keys(FUEL_STOP_REASONS) as QuoteFuelStopReasons[]).map((key) => (
                  <MenuItem value={key} key={key}>
                    <Checkbox checked={formik.values.fuelStopReasons.includes(key)} color="primary" />
                    <ListItemText primary={FUEL_STOP_REASONS[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid container size={12}>
          <Grid size={{ xs: 6, md: 3 }}>
            <TextField
              label="Customs stop"
              select
              name="customsStop"
              value={formik.values.customsStop}
              onChange={handleChange}
              disabled={formik.isSubmitting}
            >
              <MenuItem value={QuoteCustomsStop.NO_STOP}>No Stop</MenuItem>
              <MenuItem value={QuoteCustomsStop.REQUIRED}>Required</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" sx={{ my: 2 }}>
        Flight legs
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          <QuoteLegs formik={formik} dataTestId={dt('legs')} />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Note"
            name="note"
            multiline
            rows="3"
            variant="outlined"
            fullWidth
            value={formik.values.note}
            onChange={handleChange}
            error={formik.touched.note && Boolean(formik.errors.note)}
            helperText={formik.touched.note && formik.errors.note}
            disabled={formik.isSubmitting}
            inputProps={{
              'data-testid': dt('note'),
            }}
          />
        </Grid>

        <Grid size={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.floatingFleet}
                name="floatingFleet"
                color="primary"
                onChange={handleChange}
              />
            }
            label="Aircraft tail number is subject to change"
          />
        </Grid>
        <Grid size={12}>
          <OCR
            operators={operators}
            onComplete={onOCRComplete}
            onError={onOcrError}
            fileUploadRef={fileUploadRef}
            dataTestId={dt('ocr')}
          />
          <OcrErrorMessage sx={{ my: 2 }} error={ocrError} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ justifyContent: 'flex-end', flexWrap: 'nowrap', mt: 0 }}>
        {error && (
          <Grid>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
        )}
        {!error && formik.values.modifiedRouting && !currentOperatorGenuineQuote && (
          <Grid>
            <FormHelperText>
              You did not create a quote for the genuine request. While you can submit one for different date and
              airports we strongly encourage you to also provide one as requested
            </FormHelperText>
          </Grid>
        )}
        <Grid>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              navigate(searchParams.get('cancelLocation') ?? RouteConfig.RequestDetail.buildLink({ vid: request.vid }))
            }
            disabled={formik.isSubmitting || formik.values.editLeg !== null}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting || formik.values.editLeg !== null}
            data-testid={dt('create')}
          >
            {formik.isSubmitting && <CircularProgress color="primary" size={22} />}
            {!formik.isSubmitting && <span>Create</span>}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default QuoteForm;
