import { IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import React from 'react';
import useDataTestId from '~/hooks/useDataTestId';
import { PendingRequestQuoteInfo, VictorPrice } from 'fortune-client';
import { useModal } from '~/components/modals/Modal';
import ConfirmDialog from '~/components/modals/ConfirmModal';
import useMarkQuoteUnavailable from '../../api/useMarkQuoteUnavailable';
import { RouteConfig } from '~/routes/routeConfig';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';
import _isObject from 'lodash/isObject';
import { toast } from 'react-toastify';

interface QuoteActionsProps {
  requestVid?: string;
  quoteId: string;
  quotePrice?: VictorPrice;
  aircraftType?: string;
  aircraftTail?: string;
  tooltip?: string;
  availableActions: PendingRequestQuoteInfo['availableActions'];
  cancelLocation?: string;
  dataTestId?: string;
}

const QuoteActions: React.FC<QuoteActionsProps> = ({
  quoteId,
  quotePrice,
  requestVid,
  aircraftTail,
  aircraftType,
  tooltip,
  availableActions,
  cancelLocation,
  dataTestId,
}) => {
  const dt = useDataTestId(dataTestId);
  const markQuoteUnavailable = useMarkQuoteUnavailable();
  const { showModal } = useModal();

  const navigate = useNavigate();

  const handleDeleteClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const netPrice = quotePrice?.source || quotePrice;
    const result = await showModal({
      component: ConfirmDialog,
      props: {
        title: 'Mark quote as unavailable',
        text: `Are you sure you want to mark as unavailable quote ${[
          aircraftType,
          aircraftTail,
          netPrice?.locale.amount,
          netPrice?.currency,
        ]
          .filter((x) => x)
          .join(' ')}?`,
        dataTestId: dt('confimationModal'),
      },
    });

    if (result.action !== 'CONFIRM') {
      return;
    }

    try {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_MARK_AS_UNAVAILABLE);
      await markQuoteUnavailable.mutateAsync({ operatorQuoteId: quoteId });
    } catch (res) {
      let msg = 'Something went wrong! Please refresh the page and retry.';
      if (_isObject(res) && 'errors' in res && Array.isArray(res.errors) && 'detail' in res.errors[0]) {
        msg = (res.errors[0] as { detail: string }).detail;
      }
      toast.error(msg);
    }
  };

  const handleCloneClick = (event: React.MouseEvent) => {
    if (!requestVid) return;
    event.stopPropagation();
    const searchObj: { requestVid: string; cancelLocation?: string } = {
      requestVid,
    };
    if (cancelLocation) searchObj.cancelLocation = cancelLocation;
    navigate({
      pathname: RouteConfig.QuoteClone.buildLink({
        opQuote: quoteId,
      }),
      search: createSearchParams(searchObj).toString(),
    });
  };

  const deleteDisabled = !availableActions.includes('mark-as-unavailable');
  const cloneDisabled = !availableActions.includes('clone-quote');
  return (
    <>
      {!!requestVid && (
        <Tooltip title={tooltip || (cloneDisabled ? '' : 'Clone quote')}>
          <span>
            <IconButton onClick={handleCloneClick} disabled={cloneDisabled}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip title={tooltip || (deleteDisabled ? '' : 'Mark as Unavailable')}>
        <span>
          <IconButton onClick={handleDeleteClick} disabled={deleteDisabled}>
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default QuoteActions;
