import { Box, LinearProgress } from '@mui/material';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface PageEndMarkerProps {
  height?: number;
  query: UseInfiniteQueryResult;
}

const PageEndMarker = ({ height, query }: PageEndMarkerProps) => {
  const { ref, inView: isPageEndReached } = useInView({
    root: document.getElementById('box-main'),
    rootMargin: `${height ?? 100}px`,
  });
  const { hasNextPage, isFetching, fetchNextPage } = query;

  useEffect(() => {
    if (!isPageEndReached || !hasNextPage) {
      return;
    }

    // we should not use isFetchingNextPage here! it creates many problems with tabs
    if (!isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isPageEndReached]);

  return (
    <div ref={ref}>
      {query.hasNextPage || isFetching ? (
        <Box sx={{ height: height ?? 100 }}>
          <LinearProgress />
        </Box>
      ) : null}
    </div>
  );
};

export default PageEndMarker;
